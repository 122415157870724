import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";

import { getLocaleValue } from "../../../../../helpers/get-locale-value";
import Image from "../../../image";
import Navigation from "../../../navigation";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  button: {
    background: props => props.buttonBg,
    border: props => props.buttonBorder,
    borderRadius: props => props.buttonRadius,
    color: props => props.fontColor,
    fontWeight: "bold",
    boxShadow: props => props.boxShadow,
    fontSize: "1.8em",
    width: "100%",

    "&:hover": {
      textDecoration: "none",
      color: props => props.fontHoverColor,
      background: props => props.buttonHoverBg,
    },
  },
});

const BlockVideoPhone = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
}) => {
  const {
    border,
    background,
    button_border,
    button_background,
    button_alignment,
    image_variants,
    font,
    box_shadow,
    url,
  } = data;
  const imageVariant = getLocaleValue(locale, image_variants);

  const backgroundString =
    background && background.backgroundColor
      ? background.backgroundColor
      : background && background.backgroundImage
      ? `url(${background.backgroundImage})`
      : "";

  const buttonBackgroundString =
    button_background && button_background.backgroundColor
      ? button_background.backgroundColor
      : button_background && button_background.backgroundImage
      ? `url(${button_background.backgroundImage})`
      : "";

  const classes = useStyles({
    wrapperBg: backgroundString,
    buttonBg: buttonBackgroundString ? buttonBackgroundString : "",
    buttonBorder: button_border.border ? button_border.border : "",
    buttonRadius: button_border.borderRadius ? button_border.borderRadius : "",
    fontColor: font ? font.fontColor : "#595758",
    boxShadow: `${box_shadow ? box_shadow : ""}!important`,
    fontHoverColor: `${font ? font.fontHoverColor : "#ffffff"}!important`,
    buttonHoverBg: `${
      button_background.backgroundHoverColor
        ? button_background.backgroundHoverColor
        : "#f1872c"
    }!important`,
  });

  return (
    <Box
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      width="100%"
      display="flex"
      justifyContent={button_alignment.justify.value}
      alignItems={button_alignment.align_items.value}
      className={classes.wrapper}
    >
      <Navigation to={url} className={classes.button}>
        <Image
          loading="eager"
          className={classes.image}
          imageObject={imageVariant.image}
        />
      </Navigation>
    </Box>
  );
};

export default BlockVideoPhone;

import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockButtonDesktop from "./block-image-button-view-desktop";
import BlockButtonTablet from "./block-image-button-view-tablet";
import BlockButtonPhone from "./block-image-button-view-phone";

const BlockImageController = ({
  data,
  locale,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
}) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BlockButtonDesktop
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
        />
      }
      TabletComponent={
        <BlockButtonTablet
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
        />
      }
      PhoneComponent={
        <BlockButtonPhone
          data={data}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
        />
      }
    />
  );
};

export default BlockImageController;

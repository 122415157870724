import React from "react";

import { Box } from "@material-ui/core";

// import Header from "../modules/shared-components/header/header-controller";
import Header from "../../header/header-controller";
import Footer from "../../footer/footer-controller";

import DynamicZoneController from "../dynamic-zone/dynamic-zone-controller";
import SEO from "../../seo";
import { getLocaleValue } from "../../../../helpers/get-locale-value";
import { useSelector } from "react-redux";
import { getMetaDetails } from "../../../../helpers/get-meta-details";

const BlockContainerListController = ({ location, pageContext }) => {
  const {
    block_containers: blockContainers,
    sys_container_max_width: sys_container_max_width,
    hide_footer,
    header,
    footer,
    siteSetting,
    reloadRequired,
    meta_details_variants,
  } = pageContext;

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const seoData = getMetaDetails(
    locale,
    meta_details_variants,
    siteSetting,
    siteSetting
  );

  return (
    <>
      <SEO siteSetting={seoData} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100vh"
      >
        <Header
          header={header}
          siteSetting={siteSetting}
          location={location}
          reloadRequired={reloadRequired}
        />

        <div
          style={{
            flex: 1,
          }}
        >
          <DynamicZoneController
            containers={blockContainers}
            sys_container_max_width={sys_container_max_width}
            location={location}
          />
        </div>
        {!hide_footer && <Footer footer={footer} siteSetting={siteSetting} />}
      </Box>
    </>
  );
};

export default BlockContainerListController;

import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockIFrameDesktop from "./block-i-frame-view-desktop";
import BlockIFrameTablet from "./block-i-frame-view-tablet";
import BlockIFramePhone from "./block-i-frame-view-phone";

const BlockIFrameController = ({ data, locale }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={<BlockIFrameDesktop data={data} locale={locale} />}
      TabletComponent={<BlockIFrameTablet data={data} locale={locale} />}
      PhoneComponent={<BlockIFramePhone data={data} locale={locale} />}
    />
  );
};

export default BlockIFrameController;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Grid } from "@material-ui/core";
// import ContentBlockController from "../../content-blocks";
import DynamicBlockSwitcher from "../dynamic-block-switcher";

const useStyles = makeStyles({});

const DynamicZoneViewPhone = ({ containers, location }) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      {containers &&
        containers.map((container, i) => {
          const { type, items } = container;

          const sortedItems = items.sort(
            (a, b) => Number(a.order) - Number(b.order)
          );

          switch (type) {
            case "block_container":
              return (
                <Grid
                  container
                  key={`GridContainer${i}`}
                  className={`${classes.grid} ${
                    container.name == "bc-new-level"
                      ? "flex-column-reverse"
                      : ""
                  }`}
                >
                  {sortedItems.map((sortedItem, j) => {
                    const { col } = sortedItem;
                    sortedItem.item.name = sortedItem.name;

                    return (
                      <Grid item xs={col} key={`GridItem${j}`}>
                        <DynamicBlockSwitcher
                          data={sortedItem.item}
                          location={location}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            case "flex_container": // possible container
              return <div></div>;
            default:
              return <div></div>;
          }
        })}

      {/* {subSection &&
        subSection.map((n, i) => (
          <Grid container key={`GridContainer${i}`} className={classes.grid}>
            {n.items &&
              n.items.map((m, j) => (
                <Grid item lg={m.col} key={`GridItemController${j}`}>
                  <ContentBlockController data={m.item} />
                </Grid>
              ))}
          </Grid>
        ))} */}
    </Box>
  );
};

export default DynamicZoneViewPhone;

import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockButtonDesktop from "./block-button-view-desktop";
import BlockButtonTablet from "./block-button-view-tablet";
import BlockButtonPhone from "./block-button-view-phone";

const BlockImageController = ({ data, locale }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={<BlockButtonDesktop data={data} locale={locale} />}
      TabletComponent={<BlockButtonTablet data={data} locale={locale} />}
      PhoneComponent={<BlockButtonPhone data={data} locale={locale} />}
    />
  );
};

export default BlockImageController;

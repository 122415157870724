import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { Box, Typography } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";

import BlockDecoImageController from "../block-deco-image/block-deco-image-controller";
import Image from "../../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  textWrapper: {
    textAlign: "center",
    padding: "30px",
    color: props => props.fontColor,
  },
  video: {
    background: "rgba(255,255,255,0.6)",
    width: "100%",
  },
});

const ContentBlockVideoWithDecoPostDesktop = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const {
    video_variants,
    background,
    deco_image_block,
    divider,
    reverse,
    font,
  } = data;

  const backgroundString =
    background && background.backgroundColor
      ? background.backgroundColor
      : background && background.backgroundImage
      ? `url(${background.backgroundImage})`
      : "";

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
    wrapperBg: backgroundString,
    fontColor: font ? font.fontColor : "#595758",
  });

  const videoURL =
    process.env.GATSBY_ENV == "development" ? "http://localhost:1337" : "";

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      {!reverse && (
        <Box position="relative">
          <video
            loop
            autoPlay={`autoplay`}
            muted
            playsInline
            className={classes.video}
          >
            <source
              src={
                getLocaleValue(locale, video_variants).video.url.startsWith("/")
                  ? `${
                      process.env.GATSBY_ENV == "development"
                        ? "http://localhost:1337"
                        : ""
                    }${getLocaleValue(locale, video_variants).video.url}`
                  : `${process.env.GATSBY_CDN_IMAGE_URL}${
                      getLocaleValue(locale, video_variants).video.hash
                    }${getLocaleValue(locale, video_variants).video.ext}`
              }
              type={`${getLocaleValue(locale, video_variants).video.mime}`}
            />
            Your browser does not support the video tag.
          </video>

          {/* For image decoration overlay */}
          <BlockDecoImageController locale={locale} data={deco_image_block} />
        </Box>
      )}

      <Box className={classes.textWrapper}>
        {getLocaleValue(locale, video_variants).title && (
          <Typography variant="h5">
            {Parser(getLocaleValue(locale, video_variants).title)}
          </Typography>
        )}

        {getLocaleValue(locale, video_variants).description && (
          <Box width="100%">
            {Parser(getLocaleValue(locale, video_variants).description)}
          </Box>
        )}
      </Box>

      {reverse && (
        <Box position="relative">
          <video loop autoPlay={`autoplay`} muted playsInline>
            <source
              src={
                getLocaleValue(locale, video_variants).video.url.startsWith("/")
                  ? `${
                      process.env.GATSBY_ENV == "development"
                        ? "http://localhost:1337"
                        : ""
                    }${getLocaleValue(locale, video_variants).video.url}`
                  : `${process.env.GATSBY_CDN_IMAGE_URL}${
                      getLocaleValue(locale, video_variants).video.hash
                    }${getLocaleValue(locale, video_variants).video.ext}`
              }
              type={`${getLocaleValue(locale, video_variants).video.mime}`}
            />
            Your browser does not support the video tag.
          </video>

          {/* For image decoration overlay */}
          <BlockDecoImageController locale={locale} data={deco_image_block} />
        </Box>
      )}
      {divider && (
        <Box display="flex" justifyContent="center">
          <Box maxWidth="30%" pb="30px">
            <Image imageObject={divider.backgroundImage} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContentBlockVideoWithDecoPostDesktop;

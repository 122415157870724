import React from "react";

import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";
import Image from "../../../image";

const BlockDecoImageViewDesktop = ({ locale, data }) => {
  const { deco_image_variants } = data;
  const image = getLocaleValue(locale, deco_image_variants).image;

  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      display="flex"
      top="0"
      left="0"
      justifyContent={data.grid_alignment_widget.justify.value}
      alignItems={data.grid_alignment_widget.align_items.value}
    >
      <Box
        maxWidth="80%"
        position="absolute"
        top={
          data.squeeze?.top != "0" && !data.squeeze?.top
            ? data.squeeze?.top
            : ""
        }
        bottom={
          data.squeeze?.bottom != "0" && !data.squeeze?.bottom
            ? data.squeeze?.bottom
            : ""
        }
        left={
          data.squeeze?.left != "0" && !data.squeeze?.left
            ? data.squeeze?.left
            : ""
        }
        right={
          data.squeeze?.right != "0" && !data.squeeze?.right
            ? data.squeeze?.right
            : ""
        }
      >
        <Image imageObject={image} />
      </Box>
    </Box>
  );
};

export default BlockDecoImageViewDesktop;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { Box, Typography } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";
import Image from "../../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  textWrapper: {
    textAlign: "center",
    padding: "40px",
    color: props => props.fontColor,
  },
  video: {
    background: "rgba(255,255,255,0.6)",
    width: "100%",
  },
});

const ContentBlockVideoPostDesktop = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { background, divider, video_variants, font } = data;
  const { title, description, video } = getLocaleValue(locale, video_variants);

  const backgroundString =
    background && background.backgroundColor
      ? background.backgroundColor
      : background && background.backgroundImage
      ? `url(${background.backgroundImage})`
      : "";

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
    wrapperBg: backgroundString,
    fontColor: font ? font.fontColor : "#595758",
  });

  const videoURL =
    process.env.GATSBY_ENV == "development" ? "http://localhost:1337" : "";

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      <video
        className={classes.video}
        controls
        // loop
        // autoPlay={`autoplay`}
        muted
        playsInline
      >
        {/* <source src={`${videoURL}${video.url}`} type={video.mime} /> */}
        <source
          src={
            video.url.startsWith("/")
              ? `${
                  process.env.GATSBY_ENV == "development"
                    ? "http://localhost:1337"
                    : ""
                }${video.url}`
              : `${process.env.GATSBY_CDN_IMAGE_URL}${video.hash}${video.ext}`
          }
          type={`${video.mime}`}
        />
        Your browser does not support the video tag.
      </video>

      <Box className={classes.textWrapper}>
        {title && <Typography variant="h4">{Parser(title)}</Typography>}
        {description && (
          <Box
            width="100%"
            mt={title ? 2 : 0}
            display="flex"
            justifyContent="center"
          >
            <Box width="80%">
              {Parser(getLocaleValue(locale, video_variants).description)}
            </Box>
          </Box>
        )}
      </Box>

      {divider && (
        <Box display="flex" justifyContent="center">
          <Box maxWidth="20%" pb="40px">
            <Image imageObject={divider.backgroundImage} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContentBlockVideoPostDesktop;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
  },
  video: {
    background: "rgba(255,255,255,0.6)",
    width: "100%",
  },
});

const ContentBlockVideoTablet = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { video_variants } = data;
  const { video } = getLocaleValue(locale, video_variants);

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
  });

  const videoURL =
    process.env.GATSBY_ENV == "development" ? "http://localhost:1337" : ""; //TODO: CHANGE BACK PORT TO 1337

  return (
    <Box
      width="100%"
      // pt={padding_top}
      // pl={padding_left}
      // pb={padding_bottom}
      // pr={padding_right}
      // mt={margin_top}
      // ml={margin_left}
      // mb={margin_bottom}
      // mr={margin_right}
      // zIndex={z_index}
      className={classes.wrapper}
    >
      <video
        className={classes.video}
        controls
        // loop
        // autoPlay={`autoplay`}
        muted
        playsInline
        disablePictureInPicture
        controlsList="nodownload"
      >
        {/* <source src={`${videoURL}${video.url}`} type={video.mime} /> */}
        <source
          src={
            video.url.startsWith("/")
              ? `${
                  process.env.GATSBY_ENV == "development"
                    ? "http://localhost:1337"
                    : ""
                }${video.url}`
              : `${process.env.GATSBY_CDN_IMAGE_URL}${video.hash}${video.ext}`
          }
          type={`${video.mime}`}
        />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default ContentBlockVideoTablet;

import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockImagePostDesktop from "./block-image-post-view-desktop";
import BlockImagePostTablet from "./block-image-post-view-tablet";
import BlockImagePostPhone from "./block-image-post-view-phone";

const BlockImagePostController = ({
  data,
  locale,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  // const { image_variants } = data;
  // const localeData = getLocaleValue(locale, image_variants);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BlockImagePostDesktop
          data={data}
          // localeData={localeData}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      }
      TabletComponent={
        <BlockImagePostTablet
          data={data}
          // localeData={localeData}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      }
      PhoneComponent={
        <BlockImagePostPhone
          data={data}
          // localeData={localeData}
          locale={locale}
          padding_top={padding_top}
          padding_left={padding_left}
          padding_bottom={padding_bottom}
          padding_right={padding_right}
          margin_top={margin_top}
          margin_left={margin_left}
          margin_bottom={margin_bottom}
          margin_right={margin_right}
          z_index={z_index}
          translateX={translateX}
          translateY={translateY}
          translateZ={translateZ}
        />
      }
    />
  );
};

export default BlockImagePostController;

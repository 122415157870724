import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";

import { getLocaleValue } from "../../../../../helpers/get-locale-value";
import Navigation from "../../../navigation";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  button: {
    padding: "10px 15px",
    background: props => props.buttonBg,
    border: props => props.buttonBorder,
    borderRadius: props => props.buttonRadius,
    color: props => props.fontColor,
    fontWeight: "bold",
    boxShadow: props => props.boxShadow,

    "&:hover": {
      textDecoration: "none",
      color: props => props.fontHoverColor,
      background: props => props.buttonHoverBg,
    },
  },
});

const BlockVideoDesktop = ({ locale, data }) => {
  const {
    border,
    background,
    button_border,
    button_background,
    button_alignment,
    title_url_variants,
    font,
    box_shadow,
  } = data;
  const titleVariant = getLocaleValue(locale, title_url_variants);

  const backgroundString =
    background && background.backgroundColor
      ? background.backgroundColor
      : background && background.backgroundImage
      ? `url(${background.backgroundImage})`
      : "";

  const buttonBackgroundString =
    button_background && button_background.backgroundColor
      ? button_background.backgroundColor
      : button_background && button_background.backgroundImage
      ? `url(${button_background.backgroundImage})`
      : "";

  const classes = useStyles({
    wrapperBg: backgroundString,
    buttonBg: buttonBackgroundString ? buttonBackgroundString : "",
    buttonBorder: button_border.border ? button_border.border : "",
    buttonRadius: button_border.borderRadius ? button_border.borderRadius : "",
    fontColor: font ? font.fontColor : "#595758",
    boxShadow: `${box_shadow ? box_shadow : ""}!important`,
    fontHoverColor: `${font ? font.fontHoverColor : "#ffffff"}!important`,
    buttonHoverBg: `${
      button_background.backgroundHoverColor
        ? button_background.backgroundHoverColor
        : "#f1872c"
    }!important`,
  });

  return (
    <Box
      pt={4}
      pb={1}
      width="100%"
      className={classes.wrapper}
      display="flex"
      justifyContent={button_alignment.justify.value}
      alignItems={button_alignment.align_items.value}
    >
      <Navigation href={titleVariant.url} className={classes.button}>
        {titleVariant.content}
      </Navigation>
    </Box>
  );
};

export default BlockVideoDesktop;

import React from "react";

import ResponsiveWrapper from "../../responsive-wrapper";

import BlockIFrameFullHeightDesktop from "./block-i-frame-fullheight-view-desktop";
import BlockIFrameFullHeightTablet from "./block-i-frame-fullheight-view-tablet";
import BlockIFrameFullHeightPhone from "./block-i-frame-fullheight-view-phone";

const BlockIFrameFullHeightController = ({ data, locale }) => {
  return (
    <ResponsiveWrapper
      DesktopComponent={
        <BlockIFrameFullHeightDesktop data={data} locale={locale} />
      }
      TabletComponent={
        <BlockIFrameFullHeightTablet data={data} locale={locale} />
      }
      PhoneComponent={
        <BlockIFrameFullHeightPhone data={data} locale={locale} />
      }
    />
  );
};

export default BlockIFrameFullHeightController;

import React from "react";

import ResponsiveWrapper from "../responsive-wrapper";

import DynamicZoneDesktop from "./dynamic-zone-view-desktop";
import DynamicZoneTablet from "./dynamic-zone-view-tablet";
import DynamicZonePhone from "./dynamic-zone-view-phone";

import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    padding: 0,
  },
});

const DynamicZoneController = ({
  containers,
  sys_container_max_width,
  location,
}) => {
  const classes = useStyles();
  // sorting
  const sortedBlockContainers = containers.sort(
    (a, b) => Number(a.id) - Number(b.id)
  );

  //convert string "false" to boolean to prevent error showing for invalid value in maxWidth attribute
  sys_container_max_width.value =
    sys_container_max_width.value == "false"
      ? false
      : sys_container_max_width.value;

  return (
    <Container
      maxWidth={sys_container_max_width ? sys_container_max_width.value : false}
      className={classes.container}
    >
      <ResponsiveWrapper
        DesktopComponent={
          <DynamicZoneDesktop
            containers={sortedBlockContainers}
            location={location}
          />
        }
        TabletComponent={
          <DynamicZoneTablet
            containers={sortedBlockContainers}
            location={location}
          />
        }
        PhoneComponent={
          <DynamicZonePhone
            containers={sortedBlockContainers}
            location={location}
          />
        }
      />
    </Container>
  );
};

export default DynamicZoneController;

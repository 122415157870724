import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Parser from "html-react-parser";
import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    pointerEvents: "none",
    transform: props => props.transform,
    background: props => props.wrapperBg,
  },
  textWrapper: {
    padding: "15px",
    color: props => props.fontColor,
  },
});

const BlockTextViewTablet = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { background, variants, font } = data;
  const { content } = getLocaleValue(locale, variants);

  const backgroundString =
    background && background.backgroundColor
      ? background.backgroundColor
      : background && background.backgroundImage
      ? `url(${background.backgroundImage})`
      : "";

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
    wrapperBg: backgroundString,
    fontColor: font ? font.fontColor : "#595758",
  });

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      <Box className={classes.textWrapper}>
        {content && (
          <Box width="100%" display="flex" justifyContent="center">
            <Box width="100%">{Parser(content)}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BlockTextViewTablet;

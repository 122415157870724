import React from "react";
// import IframeResizer from "iframe-resizer-react";

import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";

const BlockIFrameDesktop = ({ locale, data }) => {
  const { iframe_variants } = data;
  const { url } = getLocaleValue(locale, iframe_variants);
  const headerHeight =
    typeof document !== "undefined" && document.getElementById("header")
      ? document.getElementById("header").clientHeight
      : 55;

  return (
    <Box display="flex" onScroll={e => console.log(e)}>
      <iframe
        src={url}
        width="100%"
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
          marginBottom: 0,
          border: 0,
        }}
      ></iframe>
    </Box>
    // <IframeResizer
    //   log
    //   heightCalculationMethod="lowestElement"
    //   inPageLinks
    //   src={url}
    //   scrolling={true}
    //   style={{ width: "1px", minWidth: "100%", border: "none" }}
    // />
  );
};

export default BlockIFrameDesktop;

import React, { useState } from "react";
import Parser from "html-react-parser";

import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import { getLocaleValue } from "../../../../../helpers/get-locale-value";
import Image from "../../../image";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    transform: props => props.transform,
    background: "linear-gradient(to bottom, #fff 70%, #ffe1e1)",
  },
  nonActive: {
    filter: "grayscale(1)",
  },
  video: {
    background: "rgba(255,255,255,0.6)",
    width: "100%",
  },
  textWrapper: {
    color: props => props.fontColor,
  },
});

const BlockTabContentViewDesktop = ({
  locale,
  data,
  padding_top,
  padding_left,
  padding_bottom,
  padding_right,
  margin_top,
  margin_left,
  margin_bottom,
  margin_right,
  z_index,
  translateX,
  translateY,
  translateZ,
}) => {
  const { tabs, divider, font, background } = data;

  const classes = useStyles({
    transform: `translateX(${translateX}) translateY(${translateY}) translateZ(${translateZ})`,
    fontColor: font ? font.fontColor : "#595758",
  });

  const [activeTab, setActiveTab] = useState(0);
  const videoURL =
    process.env.GATSBY_ENV == "development"
      ? "http://localhost:1337"
      : `${process.env.GATSBY_CDN_IMAGE_URL}`;

  return (
    <Box
      width="100%"
      pt={padding_top}
      pl={padding_left}
      pb={padding_bottom}
      pr={padding_right}
      mt={margin_top}
      ml={margin_left}
      mb={margin_bottom}
      mr={margin_right}
      zIndex={z_index}
      className={classes.wrapper}
    >
      <Box display="flex" justifyContent="center">
        {tabs?.map((n, i) => (
          <Box
            className={activeTab != i ? classes.nonActive : ""}
            onClick={() => setActiveTab(i)}
            key={`tab-button-${i}`}
            maxWidth="100px"
            mx={1}
            mb={3}
          >
            <Image
              loading="eager"
              imageObject={getLocaleValue(locale, n.tab_variant).image}
            />
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        {tabs?.map((n, i) => (
          <Box
            maxWidth="60%"
            pb="40px"
            display={activeTab != i ? `none` : ""}
            key={`tab-content-${i}`}
          >
            <Box
              style={{
                background:
                  n.background && n.background.backgroundColor
                    ? n.background.backgroundColor
                    : n.background && n.background.backgroundImage
                    ? `url(${n.background.backgroundImage})`
                    : "",
              }}
              width="100%"
              p="20px"
              pt={
                n.spatial
                  ? n.spatial.padding_top
                    ? n.spatial.padding_top
                    : ""
                  : ""
              }
              pl={
                n.spatial
                  ? n.spatial.padding_left
                    ? n.spatial.padding_left
                    : ""
                  : ""
              }
              pb={
                n.spatial
                  ? n.spatial.padding_bottom
                    ? n.spatial.padding_bottom
                    : ""
                  : ""
              }
              pr={
                n.spatial
                  ? n.spatial.padding_right
                    ? n.spatial.padding_right
                    : ""
                  : ""
              }
              mt={
                n.spatial
                  ? n.spatial.margin_top
                    ? n.spatial.margin_top
                    : ""
                  : ""
              }
              ml={
                n.spatial
                  ? n.spatial.margin_left
                    ? n.spatial.margin_left
                    : ""
                  : ""
              }
              mb={
                n.spatial
                  ? n.spatial.margin_bottom
                    ? n.spatial.margin_bottom
                    : ""
                  : ""
              }
              mr={
                n.spatial
                  ? n.spatial.margin_right
                    ? n.spatial.margin_right
                    : ""
                  : ""
              }
              zIndex={
                n.spatial ? (n.spatial.z_index ? n.spatial.z_index : "") : ""
              }
              border={n.border.border ? n.border.border : ""}
            >
              {!n.reverse ? (
                <video className={classes.video} controls muted playsInline>
                  <source
                    src={
                      getLocaleValue(
                        locale,
                        n.tab_variant
                      ).video.url.startsWith("/")
                        ? `${
                            process.env.GATSBY_ENV == "development"
                              ? "http://localhost:1337"
                              : ""
                          }${getLocaleValue(locale, n.tab_variant).video.url}`
                        : `${process.env.GATSBY_CDN_IMAGE_URL}${
                            getLocaleValue(locale, n.tab_variant).video.hash
                          }${getLocaleValue(locale, n.tab_variant).video.ext}`
                    }
                    type={`${getLocaleValue(locale, n.tab_variant).video.mime}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                ""
              )}
              <Box textAlign="center" className={classes.textWrapper} mt={2}>
                {getLocaleValue(locale, n.tab_variant).title && (
                  <Box width="100%" fontWeight="bold" fontSize="1.2em" mb={1}>
                    {Parser(getLocaleValue(locale, n.tab_variant).title)}
                  </Box>
                )}

                {getLocaleValue(locale, n.tab_variant).description && (
                  <Box
                    width="100%"
                    mt={getLocaleValue(locale, n.tab_variant).title ? 2 : 0}
                    display="flex"
                    justifyContent="center"
                  >
                    <Box width="80%">
                      {Parser(
                        getLocaleValue(locale, n.tab_variant).description
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
              {n.reverse ? (
                <video className={classes.video} controls muted playsInline>
                  <source
                    src={
                      getLocaleValue(
                        locale,
                        n.tab_variant
                      ).video.url.startsWith("/")
                        ? `${
                            process.env.GATSBY_ENV == "development"
                              ? "http://localhost:1337"
                              : ""
                          }${getLocaleValue(locale, n.tab_variant).video.url}`
                        : `${process.env.GATSBY_CDN_IMAGE_URL}${
                            getLocaleValue(locale, n.tab_variant).video.hash
                          }${getLocaleValue(locale, n.tab_variant).video.ext}`
                    }
                    type={`${getLocaleValue(locale, n.tab_variant).video.mime}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                ""
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {divider && (
        <Box display="flex" justifyContent="center">
          <Box maxWidth="20%" pb="40px">
            <Image imageObject={divider.backgroundImage} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BlockTabContentViewDesktop;
